








import { Component, Vue } from "vue-property-decorator";
import Ordenanzas from "@/components/Municipalidad/Ordenanzas.vue";

@Component({
  components: {
    Ordenanzas
  }
})
export default class OrdenanzasView extends Vue {}
